<template lang="">
  <div>
    <div class="d-flex align-end">
      <input-text
        ref="input"
        v-model="filtro.nome"
        :label="
          $t(
            'modulos.procedimentos_eletronicos.modal_participantes.filtro.nome'
          )
        "
        class="flex-fill"
        :filtro-global-focus="true"
      />
      <botao-padrao outlined color="secondary" class="ml-2" @click="filtrar">
        {{ $t('geral.botoes.filtrar') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      sem-acoes
      :selecionar-apenas-um="!multiplos"
    />
  </div>
</template>
<script>
import UsuarioService from '@common/services/cadastros/UsuarioService';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      participantes: [],
      filtro: {
        nome: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'participante.apelido',
            text: this.$t(
              'modulos.procedimentos_eletronicos.modal_participantes.tabela.apelido'
            ),
            sortable: false,
          },
          {
            value: 'participante.nome',
            text: this.$t(
              'modulos.procedimentos_eletronicos.modal_participantes.tabela.nome'
            ),
            sortable: false,
          },
          {
            value: 'setor.nome',
            text: this.$t(
              'modulos.procedimentos_eletronicos.modal_participantes.tabela.setor'
            ),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        this.$emit('input', this.selecionados);
      },
    },
  },
  mounted() {
    this.listar();
    this.$nextTick(() => {
      this.$refs.input.$refs.input.focus();
    });
  },
  methods: {
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.tabela.dados = this.participantes.filter(({ participante }) => {
        return participante.nome
          .toLowerCase()
          .includes(this.filtroAplicado.nome.toLowerCase());
      });
      this.tabela.quantidadeItens = this.tabela.dados.length;
      this.tabela.quantidadePaginas = Math.ceil(
        this.tabela.quantidadeItens / this.tabela.porPagina
      );
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      const { id } = this.$store.getters['Autenticacao/usuario'];
      UsuarioService.buscar(id)
        .then((res) => {
          res.data.participantes.forEach((participante) => {
            participante.id = participante.participante.id;
          });
          this.tabela.dados = res.data.participantes;
          this.participantes = res.data.participantes;
          console.log(this.participantes);
          this.tabela.quantidadeItens = res.data.participantes.length;
          this.tabela.quantidadePaginas =
            Math.ceil(this.tabela.quantidadeItens / porPagina) || 10;
          this.tabela.paginaAtual = paginaAtual;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
