<template>
  <v-form
    ref="form"
    :disabled="!podeEditar"
  >
    <botao-padrao
      v-if="podeEditar"
      @click="abrirModalReordenacao"
    >
      {{
        $t(
          'modulos.procedimentos_eletronicos.tabela_registro.reordenar_colunas'
        )
      }}
    </botao-padrao>
    <modal-padrao
      ref="modal-reordenar"
      :titulo="
        $t(
          'modulos.procedimentos_eletronicos.tabela_registro.reordenar_colunas'
        )
      "
      @ok="atualizarOrdenacao"
    >
      <tabela-padrao
        :key="keyAtualiza"
        :mostrar-checkbox="false"
        :dados="dadosColunas"
        :colunas="tabelaColunas"
        ordenavel
        sem-paginacao
        @ordenar="reordenarColunas"
      />
    </modal-padrao>
    <v-simple-table
      v-sortable-data-table
      dense
      class="mt-3 tabela-dinamica-procedimento-padrao"
      @sorted="salvarOrdenacao"
    >
      <thead>
        <tr>
          <th
            id="th-acoes"
            class="coluna-propriedades"
          />

          <th
            v-for="(coluna, i) in form.colunas"
            :id="`th-${i}`"
            :key="`th-${i}`"
            class="coluna-propriedades"
          >
            <div class="coluna-select d-flex align-center">
              <dropdown-padrao
                text
                color="secondary"
              >
                <template #botao>
                  <v-icon> $dotsVertical </v-icon>
                </template>
                <div>
                  <botao-padrao
                    v-if="podeExcluir(coluna)"
                    text
                    color="secondary"
                    @click="removerColuna(coluna)"
                  >
                    {{ $t('geral.botoes.remover') }}
                  </botao-padrao>
                </div>
              </dropdown-padrao>
              <input-select
                v-model="coluna.tipoColuna"
                sem-label
                :outlined="false"
                flat
                solo
                background-color="cinzaTabela"
                :options="opcoesCalculadas(coluna)"
                :clearable="false"
                class="flex-fill input-tabela mt-2"
                :disabled="!podeExcluir(coluna)"
                @change="recalcularColunas(coluna)"
              >
                <template v-slot:selection="item">
                  {{ item.item.text }}
                  {{ coluna.indice }}
                </template>
              </input-select>
            </div>
          </th>

          <th id="th-botao">
            <botao-padrao
              v-if="podeEditar"
              text
              color="secondary"
              @click="adicionarColuna"
            >
              <v-icon> $plus </v-icon>
              {{
                $t(
                  'modulos.procedimentos_eletronicos.tabela_dinamica.botoes.adicionar_coluna'
                )
              }}
            </botao-padrao>
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- Visivel -->
        <tr>
          <td class="headcol">
            {{
              $t(
                'modulos.procedimentos_eletronicos.tabela_dinamica.linhas_fixas.visivel'
              )
            }}
          </td>
          <td
            v-for="(coluna, i) in form.colunas"
            :key="`visivel-${i}`"
            class="coluna-propriedades"
          >
            <input-select
              v-model="coluna.visivel"
              sem-label
              flat
              solo
              background-color="transparent"
              :options="opcoes.boleano"
              :clearable="false"
              class="flex-fill input-tabela mt-2"
            />
          </td>
          <td />
        </tr>
        <!-- Cabecalho -->
        <tr>
          <td class="headcol">
            {{
              $t(
                'modulos.procedimentos_eletronicos.tabela_dinamica.linhas_fixas.cabecalho'
              )
            }}
          </td>
          <td
            v-for="(coluna, i) in form.colunas"
            :key="`cabecalho-${i}`"
            class="coluna-propriedades"
          >
            <input-text
              v-model="coluna.cabecalho"
              sem-label
              flat
              solo
              background-color="transparent"
              class="flex-fill input-tabela mt-2"
              obrigatorio
            />
          </td>
          <td />
        </tr>
        <!-- Sentido -->
        <tr>
          <td
            style="border-bottom: 1px solid #d5d6d9"
            class="headcol"
          >
            {{
              $t(
                'modulos.procedimentos_eletronicos.tabela_dinamica.linhas_fixas.sentido'
              )
            }}
          </td>
          <td
            v-for="(coluna, i) in form.colunas"
            :key="`sentido-${i}`"
            class="coluna-propriedades"
            style="border-bottom: 1px solid #d5d6d9"
          >
            <input-select
              v-if="temSentido(coluna)"
              v-model="coluna.sentido"
              sem-label
              flat
              solo
              background-color="transparent"
              :options="opcoes.sentido"
              :clearable="false"
              class="flex-fill input-tabela mt-2"
            >
              <template v-slot:item="item">
                <v-icon>{{ retornarIconeDirecao(item.item.value) }}</v-icon>
              </template>
              <template v-slot:selection="item">
                <v-icon>{{ retornarIconeDirecao(item.item.value) }}</v-icon>
              </template>
            </input-select>
          </td>
          <td style="border-bottom: 1px solid #d5d6d9" />
        </tr>
        <!-- Variavel -->
        <tr>
          <td class="headcol">
            {{
              $t(
                'modulos.procedimentos_eletronicos.tabela_dinamica.linhas_fixas.variavel'
              )
            }}
          </td>
          <td
            v-for="(coluna, i) in form.colunas"
            :key="`variavel-${i}`"
            class="coluna-propriedades"
          >
            <div v-if="coluna.tipoColuna == 'TabelaAuxiliar'">
              <input-select
                v-if="temVariavel(coluna)"
                ref="tabelaAuxiliarColunaId"
                v-model="coluna.tabelaAuxiliarColunaId"
                sem-label
                flat
                solo
                :options="opcoes.tiposColunasAuxiliar"
                background-color="transparent"
                :clearable="false"
                class="flex-fill input-tabela mt-2"
                obrigatorio
              />
            </div>
            <div v-else>
              <input-select
                v-if="temVariavel(coluna)"
                v-model="coluna.formulaVariavelId"
                sem-label
                flat
                solo
                :options="opcoes.variaveis"
                background-color="transparent"
                :clearable="false"
                class="flex-fill input-tabela mt-2"
                obrigatorio
              />
            </div>
          </td>
          <td />
        </tr>
        <!-- Unidade -->
        <tr>
          <td class="headcol">
            {{
              $t(
                'modulos.procedimentos_eletronicos.tabela_dinamica.linhas_fixas.unidade'
              )
            }}
          </td>
          <td
            v-for="(coluna, i) in form.colunas"
            :key="`unidadeMedidaId-${i}`"
            class="coluna-propriedades"
          >
            <input-unidade-medida
              v-if="temUnidade(coluna)"
              v-model="coluna.unidadeMedidaId"
              class="flex-fill input-tabela mt-2"
              :multiplos="false"
              sem-label
              flat
              solo
              background-color="transparent"
              obrigatorio
            />
          </td>
          <td />
        </tr>
        <!-- Fórmula -->
        <tr>
          <td class="headcol">
            {{
              $t(
                'modulos.procedimentos_eletronicos.tabela_dinamica.linhas_fixas.formula'
              )
            }}
          </td>
          <td
            v-for="(coluna, i) in form.colunas"
            :key="`formula-${i}`"
            class="formula-propriedades"
          >
            <input-formula
              v-if="temFormula(coluna)"
              v-model="coluna.formulaId"
              :titulo-modal="
                $t(
                  'modulos.procedimentos_eletronicos.tabela_dinamica.modal_formula'
                )
              "
              :formula-mensurando-id="form.formulaMensurandoId.value"
              :incluir-formula-pai="!somenteFilhasNaoSistema(coluna)"
              :multiplos="false"
              sem-label
              flat
              solo
              obrigatorio
              background-color="transparent"
              class="flex-fill input-tabela mt-2"
              :disabled="!podeExcluir(coluna)"
            />
          </td>
          <td />
        </tr>
        <!-- Variavel -->
        <tr>
          <td class="headcol">
            {{
              $t(
                'modulos.procedimentos_eletronicos.tabela_dinamica.linhas_fixas.tipo_calculo'
              )
            }}
          </td>
          <td
            v-for="(coluna, i) in form.colunas"
            :key="`variavel-${i}`"
            class="coluna-propriedades"
          >
            <input-select
              v-if="temTipoCalculo(coluna)"
              v-model="coluna.tabelaReferencia"
              sem-label
              flat
              solo
              :options="opcoes.tiposCalculos"
              background-color="transparent"
              :clearable="false"
              class="flex-fill input-tabela mt-2"
              obrigatorio
            />
          </td>
          <td />
        </tr>
      </tbody>
      <tbody>
        <!--Aqui começa as linhas-->
        <tr
          v-for="(linha, l) in form.linhas"
          :key="`linha-${linha.identificador}`"
        >
          <td class="headcol">
            <div class="d-flex align-center">
              <dropdown-padrao
                text
                color="secondary"
              >
                <template #botao>
                  <v-icon> $dotsVertical </v-icon>
                </template>
                <div>
                  <botao-padrao
                    text
                    color="secondary"
                    @click="removerLinha(linha)"
                  >
                    {{ $t('geral.botoes.remover') }}
                  </botao-padrao>
                </div>
              </dropdown-padrao>
              <span>{{ l + 1 }}</span>
            </div>
          </td>
          <td
            v-for="(coluna, i) in colunasLinhas"
            :key="`linha-coluna-${i}`"
            class="coluna-propriedades"
          >
            <input-text
              v-if="temTexto(coluna)"
              v-model="linha[coluna.nomeVariavel]"
              sem-label
              flat
              solo
              background-color="transparent"
              class="flex-fill input-tabela mt-2"
              :type="tipoCampo(coluna)"
              :regras="regras(coluna)"
            />
          </td>
          <td />
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td
            :colspan="quantidadeColunas"
            style="border-top: 1px solid #d5d6d9"
          >
            <botao-padrao
              v-if="podeEditar"
              text
              color="secondary"
              @click="adicionarLinha"
            >
              <v-icon> $plus </v-icon>
              {{
                $t(
                  'modulos.procedimentos_eletronicos.tabela_dinamica.botoes.adicionar_linha'
                )
              }}
            </botao-padrao>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-form>
</template>
<script>
import helpers from '@common/utils/helpers';
import _ from 'lodash';
import Sortable from 'sortablejs';
import { InputUnidadeMedida, InputFormula } from '@components/inputs';
import validacoes from '@common/utils/validacoes';
import FormulaService from '@common/services/cadastros/FormulaService';
import { TipoColunaConst } from '@const/TipoColunaConst';
import { SentidoConst } from '@const/SentidoConst';
import { EnumeradorService } from '@common/services/cadastros';
import ProcedimentoEletronicoService from '@common/services/cadastros/ProcedimentoEletronicoService';
export default {
  components: { InputUnidadeMedida, InputFormula },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit('sorted', event);
          },
        };
        Sortable.create(el.getElementsByTagName('tbody')[1], options);
      },
    },
  },
  props: {
    form: {},
    unidadeMedidaPai: { type: Object, default: () => {} },
  },
  data() {
    return {
      opcoes: {
        tiposColunas: helpers.TipoColunaEnum,
        boleano: helpers.BoleanoEnum,
        variaveis: [],
        unidades: [],
        sentido: [
          { value: SentidoConst.DeCimaParaBaixo, text: 'cima' },
          { value: SentidoConst.DeBaixoParaCima, text: 'baixo' },
          { value: SentidoConst.DaEsquerdaParaDireita, text: 'direita' },
          {
            value: SentidoConst.DeCimaParaBaixoProximaTabela,
            text: 'cimaProxima',
          },
          {
            value: SentidoConst.DeBaixoParaCimaProximaTabela,
            text: 'baixoProxima',
          },
        ],
        tiposCalculos: [],
        tiposColunasAuxiliar: []
      },
      dadosColunas: [],
      tabelaColunas: [
        {
          value: 'tipoColuna',
          text: 'Coluna',
          formatter: (a, b) => {
            return `${b.tipoColuna} ${b.indice}`;
          },
          sortable: false,
          width: '50%',
        },
        {
          value: 'cabecalho',
          text: 'Cabeçalho',
          sortable: false,
          width: '50%',
        },
      ],
      keyAtualiza: 0,
    };
  },
  computed: {
    quantidadeColunas: function () {
      return this.form.colunas.length + 2;
    },
    colunasLinhas: function () {
      let colunas = [];
      this.form.colunas.forEach((coluna) => {
        let nomeVariavel = '';
        if (coluna.tipoColuna == TipoColunaConst.REFERENCIA)
          nomeVariavel = 'valorReferencia';
        if (coluna.tipoColuna == TipoColunaConst.TEXTO)
          nomeVariavel = `valorTexto${coluna.indice}`;

        colunas.push({
          tipoColuna: coluna.tipoColuna,
          nomeVariavel: nomeVariavel,
        });
      });
      return colunas;
    },
    ehRevisao: function () {
      return this.$route.name === 'procedimento-eletronico-revisao';
    },
    podeEditar: function () {
      return (
        !this.ehRevisao &&
        this.form.formulaMensurandoId &&
        this.form.incertezasIds.length > 0
      );
    },
  },
  watch: {
    'form.formulaMensurandoId': {
      deep: true,
      immediate: true,
      handler(formula) {
        if (formula) {
          this.listarVariaveis(formula.value);
        }
      },
    },
    'form.tabelaAuxiliarId': {
      handler() {
        if(this.form.tabelaAuxiliarId != null) {
          this.listarColunasAuxiliar()
        }
        if(this.form.tabelaAuxiliarId == null) {
          this.opcoes.tiposColunasAuxiliar = [];
          this.form.colunas = this.form.colunas.filter(coluna => coluna.tipoColuna !== TipoColunaConst.TABELAAUXILIAR);

          this.form.colunas.forEach((coluna, index) => {
            if (coluna.tipoColuna === TipoColunaConst.TABELAAUXILIAR) {
                this.form.colunas.splice(index, 1);
              }
          });
        }
      }
    },
    'form.flagTabelaAuxiliar': {
      handler() {
        if(this.form.flagTabelaAuxiliar) {
          this.form.colunas.forEach((coluna, index) => {
            if (coluna.tipoColuna === TipoColunaConst.TABELAAUXILIAR) {
                this.form.colunas.splice(index, 1);
              }
          });
        }
      }
    }
  },
  mounted() {
    this.buscarTipoCalculos();
  },
  methods: {
    buscarTipoCalculos: function () {
      EnumeradorService.buscar('EnumTabelaReferencia').then((res) => {
        this.opcoes.tiposCalculos = res;
      });
    },
    podeExcluir: function (coluna) {
      if (coluna.tipoColuna != TipoColunaConst.RESULTADO || !coluna.formulaId)
        return true;
      if (coluna.formulaId?.text.includes('Incerteza'))
        return (
          this.form.colunas.filter(
            (el) =>
              el.tipoColuna == TipoColunaConst.RESULTADO &&
              el.formulaId?.text.includes('Incerteza')
          ).length > 1
        );
      if (coluna.formulaId?.text.includes('Erro'))
        return (
          this.form.colunas.filter(
            (el) =>
              el.tipoColuna == TipoColunaConst.RESULTADO &&
              el.formulaId?.text.includes('Erro')
          ).length > 1
        );

      return true;
    },
    atualizarOrdenacao: function () {
      this.form.colunas = _.cloneDeep(this.dadosColunas);
      this.reordenarIndice();
      this.$refs['modal-reordenar'].fecharModal();
    },
    reordenarColunas: function (e) {
      this.dadosColunas = e;
      this.keyAtualiza++;
    },
    abrirModalReordenacao: function () {
      this.dadosColunas = _.cloneDeep(this.form.colunas);
      this.$refs['modal-reordenar'].abrirModal();
    },
    listarVariaveis: function (id) {
      FormulaService.variaveis(id).then((response) => {
        this.opcoes.variaveis = response.data.map((el) => {
          return {
            value: el.id,
            text: `${el.simbolo} - ${el.unidadeMedida.nome}`,
          };
        });
      });
    },
    listarColunasAuxiliar: function () {
      ProcedimentoEletronicoService.buscarTabelaRegistro(
        this.form.tabelaAuxiliarId
      )
        .then((res) => {
          this.opcoes.tiposColunasAuxiliar = res.data.colunas.map((el) => {
            return {
                value: el.id,
                text: el.cabecalho,
              };
          })
      })
    },
    adicionarColuna: function () {
      let tipoColuna = TipoColunaConst.TEXTO;
      if (
        this.form.colunas.filter((el) => el.tipoColuna == TipoColunaConst.TEXTO)
          .length == 5
      )
        tipoColuna = 2;
      if (
        this.form.colunas.filter(
          (el) => el.tipoColuna == TipoColunaConst.REFERENCIA
        ).length == 1 &&
        tipoColuna == 2
      )
        tipoColuna = 3;

      let indice =
        this.form.colunas.filter((el) => el.tipoColuna == tipoColuna).length +
        1;

      this.form.colunas.push({
        tipoColuna: tipoColuna,
        tipoColunaAntiga: tipoColuna,
        cabecalho: '',
        visivel: true,
        sentido: SentidoConst.DeCimaParaBaixo,
        unidadeMedidaId: this.unidadeMedidaPai,
        indice: indice,
        indiceAntigo: indice,
      });
      setTimeout(() => {
        this.$refs.form.validate();
      }, 500);
    },
    adicionarLinha: function () {
      this.form.linhas.push({
        identificador: `linha-${helpers.gerarNumeroAleatorio()}`,
        valorReferencia: 0,
        valorTexto1: '',
        valorTexto2: '',
        valorTexto3: '',
        valorTexto4: '',
        valorTexto5: '',
      });
    },
    removerColuna: function (coluna) {
      this.form.colunas.splice(this.form.colunas.indexOf(coluna), 1);
      this.$emit('validar-tabela');
    },
    removerLinha: function (linha) {
      this.form.linhas.splice(this.form.linhas.indexOf(linha.form), 1);
    },

    ordernarHorizontal: function (posicao, posicao2) {
      this.$nextTick(() => {
        let dadosColunaAtual = this.form.colunas[posicao];
        this.form.colunas[posicao] = this.form.colunas[posicao2];
        this.form.colunas[posicao2] = dadosColunaAtual;

        if (
          this.form.colunas[posicao].tipoColuna == TipoColunaConst.TEXTO &&
          this.form.colunas[posicao2].tipoColuna == TipoColunaConst.TEXTO
        ) {
          let indice1 = this.form.colunas[posicao].indice;
          let indice2 = this.form.colunas[posicao2].indice;
          this.form.linhas = this.form.linhas.map((el) => {
            let aux = el[`valorTexto${indice1}`];
            el[`valorTexto${indice1}`] = el[`valorTexto${indice2}`];
            el[`valorTexto${indice2}`] = aux;

            return el;
          });
        }

        this.reordenarIndice();
      });
    },

    opcoesCalculadas: function (form) {
      let exclusao = [];
      if (
        form.tipoColuna != TipoColunaConst.TEXTO &&
        this.form.colunas.filter((el) => el.tipoColuna == TipoColunaConst.TEXTO)
          .length == 5
      )
        exclusao.push(TipoColunaConst.TEXTO);
      if (
        form.tipoColuna != TipoColunaConst.REFERENCIA &&
        this.form.colunas.filter(
          (el) => el.tipoColuna == TipoColunaConst.REFERENCIA
        ).length == 1
      )
        exclusao.push(TipoColunaConst.REFERENCIA);

      if(this.form.flagTabelaAuxiliar || this.form.tabelaAuxiliarId == null) {
        exclusao.push(TipoColunaConst.TABELAAUXILIAR);
      }

      return this.opcoes.tiposColunas.filter(
        (el) => !exclusao.includes(el.value)
      );
    },

    reordenarIndice: function () {
      let colunas = [];
      this.form.colunas.forEach((coluna) => {
        let indice =
          colunas.filter((el) => el.tipoColuna == coluna.tipoColuna).length + 1;

        coluna.indice = indice;
        coluna.indiceAntigo = indice;
        coluna.tipoColunaAntiga = coluna.tipoColuna;
        colunas.push(coluna);
      });

      this.form.colunas = colunas;
    },
    recalcularColunas: function (coluna) {
      let backupColuna = _.cloneDeep(coluna);
      this.$nextTick(() => {
        this.reordenarIndice();

        if (backupColuna.tipoColunaAntiga == TipoColunaConst.TEXTO) {
          this.form.linhas = this.form.linhas.map((el) => {
            for (
              let index = backupColuna.indiceAntigo - 1;
              index < 5;
              index++
            ) {
              el[`valorTexto${index + 1}`] = el[`valorTexto${index + 2}`] || '';
            }
            return el;
          });
        }

        if (coluna.tipoColuna == TipoColunaConst.TEXTO) {
          this.form.linhas = this.form.linhas.map((el) => {
            for (let index = 5; index > coluna.indice; index--) {
              el[`valorTexto${index}`] = el[`valorTexto${index - 1}`] || '';
            }
            el[`valorTexto${coluna.indice}`] = '';
            return el;
          });
        }

        if(coluna.tipoColuna == TipoColunaConst.TABELAAUXILIAR) {
          this.form.linhas = this.form.linhas.map((el) => {
            for (let index = 5; index > coluna.indice; index--) {
              el[`valorTexto${index}`] = el[`valorTexto${index - 1}`] || '';
            }
            el[`valorTexto${coluna.indice}`] = '';
            return el;
          });
        }
      });
    },

    retornarIconeDirecao: function (direcao) {
      switch (direcao) {
        case SentidoConst.DeBaixoParaCima:
          return '$arrowUp';
        case SentidoConst.DeCimaParaBaixo:
          return '$arrowDown';
        case SentidoConst.DaEsquerdaParaDireita:
          return '$arrowRight';
        case SentidoConst.DeCimaParaBaixoProximaTabela:
          return '$cimaProxima';
        case SentidoConst.DeBaixoParaCimaProximaTabela:
          return '$baixoProxima';
      }
    },
    temSentido: function (form) {
      return (
        form.tipoColuna == TipoColunaConst.SMC ||
        form.tipoColuna == TipoColunaConst.SMP
      );
    },
    temUnidade: function (form) {
      return form.tipoColuna == TipoColunaConst.UNIDADE;
    },
    temVariavel: function (form) {
      return (
        form.tipoColuna == TipoColunaConst.SMP || form.tipoColuna == TipoColunaConst.TABELAAUXILIAR ||
        (form.tipoColuna == TipoColunaConst.REFERENCIA &&
          (form.tabelaReferencia == 'SomatorioPontosPadrao' ||
            form.tabelaReferencia == 'SomatorioPontosPadraoTorque'))
      );
    },
    temTexto: function (form) {
      return form.tipoColuna == TipoColunaConst.TEXTO;
    },
    temTipoCalculo: function (form) {
      return form.tipoColuna == TipoColunaConst.REFERENCIA;
    },
    somenteFilhasNaoSistema: function (form) {
      return form.tipoColuna == TipoColunaConst.REFERENCIA;
    },
    temFormula: function (form) {
      return form.tipoColuna == TipoColunaConst.RESULTADO;
    },
    tipoCampo: function (form) {
      return form.tipoColuna == TipoColunaConst.REFERENCIA ? 'number' : '';
    },
    regras: function (form) {
      let regras = [];
      if (form.tipoColuna == TipoColunaConst.REFERENCIA)
        regras.push(validacoes.regraValorAmplitude);
      return regras;
    },
    salvarOrdenacao(event) {
      this.$nextTick(() => {
        const movedItem = this.form.linhas.splice(event.oldIndex, 1)[0];
        this.form.linhas.splice(event.newIndex, 0, movedItem);
      });
    },
  },
};
</script>
<style lang="scss">
.coluna-select {
  width: 200px;
  font-weight: 400;
}
.tabela-dinamica-procedimento-padrao {
  th {
    background-color: $color-primary-grey-100;
  }
  .coluna-propriedades {
    width: 0;
  }
}
.btn-reordenar .v-btn {
  padding: 5px !important;
  height: fit-content !important;
}
.coluna-select .w-100 {
  width: fit-content !important;
}

.headcol {
  position: sticky;
  left: 0;
  background-color: #f7f7f7;
  z-index: 10;
}

</style>
<!-- tbody td:first-child,
thead th:first-child {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  background: #f1f1f1;
  z-index: 2;
}

tbody td:first-child {
  z-index: 1;
} -->
