<template>
  <div>
    <v-form
      ref="form"
      :disabled="ehRevisao"
    >
      <div class="row mt-3">
        <input-text
          v-model="form.nome"
          class="col-12 col-md-2"
          :label="$t('modulos.procedimentos_eletronicos.formulario.nome')"
          obrigatorio
          trim
        />
        <input-select
          v-model="form.tipoProcedimento"
          class="col-12 col-md-2"
          :label="
            $t('modulos.procedimentos_eletronicos.formulario.tipo_procedimento')
          "
          :options="opcoes.tipoProcedimentoEletronico"
          obrigatorio
        />
        <input-tipo-instrumento
          v-model="form.tiposInstrumentos"
          class="col-12 col-md-4"
          :label="
            $t('modulos.procedimentos_eletronicos.formulario.tipo_instrumento')
          "
          obrigatorio
        />
        <input-escopo-servico
          ref="inputEscopo"
          v-model="form.escopos"
          class="col-12 col-md-4"
          :label="
            $t('modulos.procedimentos_eletronicos.formulario.escopo_servico')
          "
          obrigatorio
          prevent-acoes
          @prevent-abrir-modal="abrirModalEscopo"
          @prevent-remover-opcao="removerOpcaoEscopo"
        />
        <input-select
          v-model="form.tiposCalibracoes"
          class="col-12 col-md-2"
          :label="
            $t('modulos.procedimentos_eletronicos.formulario.tipo_calibracao')
          "
          :options="opcoes.tipoCalibracao"
          chips
          multiplos
          obrigatorio
        />

        <input-select
          v-model="form.locaisExecucoesIds"
          :options="opcoes.localExecucao"
          class="col-12 col-md-2"
          :label="$t('modulos.procedimentos_eletronicos.formulario.local')"
          multiplos
          chips
          obrigatorio
        />
        <input-date
          v-model="form.dataUltimaAnaliseCritica"
          class="col-12 col-md-3"
          :obrigatorio="!!form.periodoRevisaoMeses"
          :label="
            $t(
              'modulos.procedimentos_eletronicos.formulario.data_analise_critica'
            )
          "
          apenas-datas-passadas
        />
        <input-text
          v-model="form.periodoRevisaoMeses"
          class="col-12 col-md-3"
          type="number"
          :obrigatorio="!!form.dataUltimaAnaliseCritica"
          :label="
            $t('modulos.procedimentos_eletronicos.formulario.periodo_revisao')
          "
        />
        <input-date
          v-model="form.dataProximaAnaliseCritica"
          class="col-12 col-md-2"
          disabled
          :label="
            $t('modulos.procedimentos_eletronicos.formulario.proxima_analise')
          "
        />
        <input-text
          v-model="form.notasCertificado"
          class="col-12"
          :label="
            $t('modulos.procedimentos_eletronicos.formulario.notasCertificado')
          "
          :min="0"
          :max="500"
        />
        <input-textarea
          v-if="form.revisao > 0"
          :key="justificativaKey"
          v-model="form.justificativa"
          class="col-12"
          :label="
            $t('modulos.procedimentos_eletronicos.formulario.justificativa')
          "
          :min="0"
          :max="500"
          :obrigatorio="justificativaObrigatoria"
        />
        <accordion-padrao
          :titulo="
            $t('modulos.procedimentos_eletronicos.formulario.tabela.titulo')
          "
          :nao-retrair="true"
          class="col-12"
        >
          <tabela-dados-procedimento
            :form="form.participantes"
            :qtd-participantes="qtdParticipantes"
            :eh-revisao="ehRevisao"
          />
        </accordion-padrao>
      </div>
    </v-form>
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import TipoCalibracaoService from '@common/services/cadastros/TipoCalibracaoService';
import LocalExecucaoService from '@common/services/cadastros/LocalExecucaoService';
import TabelaDadosProcedimento from './TabelaDadosProcedimento.vue';
import { InputTipoInstrumento, InputEscopoServico } from '@components/inputs';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import { mapGetters } from 'vuex';
export default {
  components: {
    InputTipoInstrumento,
    InputEscopoServico,
    TabelaDadosProcedimento,
  },
  props: {
    form: {},
    qtdParticipantes: { type: Number, default: 0 },
  },
  data() {
    return {
      justificativaObrigatoria: false,
      justificativaKey: 0,
      opcoes: {
        tipoProcedimentoEletronico: [],
        tipoCalibracao: [],
        localExecucao: [],
      },
      dados: [],
    };
  },
  computed: {
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),
    ehRevisao: function () {
      return this.$route.name === 'procedimento-eletronico-revisao';
    },
  },
  watch: {
    'form.dataUltimaAnaliseCritica': function () {
      this.calcularProximaAnalise();
    },
    'form.periodoRevisaoMeses': function () {
      this.calcularProximaAnalise();
    },
  },
  mounted() {
    this.buscarTipoProcedimentoEletronico();
  },
  methods: {
    podeJustificativaNula() {
      this.justificativaObrigatoria = false;
      ++this.justificativaKey;
    },
    obrigarPreencherJustificativa: async function() {
      this.toastAlerta(this.$t('modulos.procedimentos_eletronicos.alertas.justificativa'));
      this.justificativaObrigatoria = true;
      await this.$nextTick();
      this.$refs.form.validate();
    },
    buscarDependentesEscopo: function () {
      this.buscarTipoCalibracao();
      this.buscarLocalExecucao();

      this.$refs.form.resetValidation();
    },
    abrirModalEscopo: function () {
      if (this.form.escopos.length == 0) {
        this.$refs.inputEscopo.abrirModal();
        return;
      }

      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t(
          'modulos.procedimentos_eletronicos.formulario.confirmar_alteracao_dados'
        )
      ).then(() => {
        this.$refs.inputEscopo.abrirModal();
      });
    },
    removerOpcaoEscopo: function (indice) {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t(
          'modulos.procedimentos_eletronicos.formulario.confirmar_alteracao_dados'
        )
      ).then(() => {
        this.$refs.inputEscopo.removerOpcao(indice);
      });
    },
    buscarTipoCalibracao: function () {
      this.opcoes.tipoCalibracao = [];

      TipoCalibracaoService.listar(
        {},
        this.form.escopos.map((el) => el.value)
      ).then((res) => {
        let tipoCalibracao = res.data;
        if (!this.participanteLogadoConta) {
          tipoCalibracao = tipoCalibracao.filter(
            (t) => t.acessivelClientes == true
          );
        }
        this.opcoes.tipoCalibracao = new DropdownModel(tipoCalibracao);
      });
    },
    buscarLocalExecucao: function () {
      this.opcoes.localExecucao = [];

      LocalExecucaoService.listar(
        {},
        this.form.escopos.map((el) => el.value)
      ).then((res) => {
        this.opcoes.localExecucao = new DropdownModel(res.data);
      });
    },
    buscarTipoProcedimentoEletronico: function () {
      EnumeradorService.buscar('EnumTipoProcedimento').then((res) => {
        this.opcoes.tipoProcedimentoEletronico = res;
      });
    },
    calcularProximaAnalise: function () {
      this.form.dataProximaAnaliseCritica = null;
      if (this.form.dataUltimaAnaliseCritica && this.form.periodoRevisaoMeses)
        this.form.dataProximaAnaliseCritica = helpers.adicionarData(
          this.form.dataUltimaAnaliseCritica,
          this.form.periodoRevisaoMeses,
          'month'
        );
        this.$nextTick().then(() => this.$refs.form.validate());
    },
  },
};
</script>
