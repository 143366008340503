<template>
  <div>
    <div class="d-flex justify-space-between mt-3">
      <div class="d-flex align-center">
        <botao-padrao
          v-if="podeExcluir"
          color="secondary"
          outlined
          @click="movimentarItem('cima')"
        >
          <v-icon> $arrowUp </v-icon>
        </botao-padrao>

        <botao-padrao
          v-if="podeExcluir"
          color="secondary"
          outlined
          class="mx-2"
          @click="movimentarItem('baixo')"
        >
          <v-icon> $arrowDown </v-icon>
        </botao-padrao>
        <botao-padrao
          v-if="podeExcluir"
          class="my-2"
          outlined
          color="danger"
          @click="excluirRegistros"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <botao-padrao
        v-if="podeEditar"
        @click="abrirNovo"
      >
        {{
          $t(
            'modulos.procedimentos_eletronicos.tabela_registro.botao_adicionar'
          )
        }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="tabela.selecionados"
      :ordenavel="podeEditar"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      selecionar-apenas-um
      sem-paginacao
    >
      <template v-slot:item.sequencia="{ item }">
        <div>
          {{ item.index + 1 }}
        </div>
      </template>
      <template v-slot:item.acoes="{ item }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item
            v-if="!podeEditar"
            @click="abrirEditar(item.item)"
          >
            {{ $t('geral.botoes.visualizar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="podeEditar"
            @click="abrirEditar(item.item)"
          >
            {{ $t('geral.botoes.editar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="podeEditar"
            @click="duplicarTabelaRegistro(item.item)"
          >
            {{ $t('geral.botoes.duplicar') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
    </tabela-padrao>

    <v-divider class="my-5" />

    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        v-if="!ehRevisao"
        class="mx-2"
        sem-capitalize
        @click="confirmacaoSalvar('continuar')"
      >
        <v-icon>$mdiContentSaveMoveOutline</v-icon>
        {{ $t('geral.botoes.salvar_continuar') }}
      </botao-padrao>
      <botao-padrao
        v-if="!ehRevisao"
        class="mr-2"
        sem-capitalize
        @click="confirmacaoSalvar('sair')"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar_sair') }}
      </botao-padrao>
      <botao-padrao
        v-if="!ehRevisao"
        sem-capitalize
        @click="publicarProcedimento"
      >
        <v-icon>$mdiPublish</v-icon>
        {{ $t('geral.botoes.publicar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import ProcedimentoEletronicoService from '@/common/services/cadastros/ProcedimentoEletronicoService';
import helpers from '@/common/utils/helpers';

export default {
  props: {
    id: { type: String, default: '' },
    form: { type: Object, default: null },
  },
  data() {
    return {
      valido: false,
      primeiraVez: false,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'sequencia',
            text: this.$t(
              'modulos.procedimentos_eletronicos.tabela_registro.tabela.sequencia'
            ),
            sortable: true,
          },
          {
            value: 'nome',
            text: this.$t(
              'modulos.procedimentos_eletronicos.tabela_registro.tabela.nome'
            ),
            sortable: true,
          },
          {
            value: 'faixa',
            text: this.$t(
              'modulos.procedimentos_eletronicos.tabela_registro.tabela.faixa'
            ),
            sortable: true,
            formatter: (valor, linha) => {
              const faixa = `(${linha?.amplitudeMinima} a ${linha?.amplitudeMaxima}) ${linha?.unidadeMedida?.nome}`;
              return helpers.formatarDescricao(faixa);
            },
          },
        ],
      },
      filtroAplicado: { filter: '' },
    };
  },
  computed: {
    podeExcluir() {
      return !(
        this.tabela.selecionados.length != 1 ||
        this.ehRevisao ||
        this.form.revisaoPublicada
      );
    },
    podeEditar() {
      return !this.ehRevisao && !this.form.revisaoPublicada;
    },
    ehRevisao() {
      return this.$route.name === 'procedimento-eletronico-revisao';
    },
  },
  watch: {
    'tabela.dados'() {
      if (this.primeiraVez) this.$emit('modificar-formulario');
      this.primeiraVez = true;
    },
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.procedimentos_eletronicos.titulos.listagem')
    );
    this.listarRegistros();
    this.$emit('modificar-formulario', false);
  },
  methods: {
    duplicarTabelaRegistro(item) {
      this.$store.dispatch('Layout/iniciarCarregamento');

      ProcedimentoEletronicoService.duplicarTabelaRegistro(item.id)
        .then((response) => {
          this.toastSucesso(
            this.$t(`modulos.procedimentos_eletronicos.duplicar_sucesso`, {
              SEQUENCIA: response.data,
            })
          );

          this.listarRegistros();
        })
        .catch(() => {
          this.toastErro(this.$t(`geral.erros.erroGenerico`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    listarRegistros() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ProcedimentoEletronicoService.listarTabelas(this.id)
        .then((res) => {
          this.valido = false;
          this.tabela.selecionados = [];
          this.tabela.dados = res.data;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.$emit('modificar-formulario', false);
        });
    },
    excluirRegistros() {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        ProcedimentoEletronicoService.excluirTabelaRegistro(
          this.tabela.selecionados[0].id
        )
          .then(() => {
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
            this.toastSucesso(
              this.$t(
                `modulos.procedimentos_eletronicos.tabela_registro.exclusao_sucesso`
              )
            );
          })
          .catch(() => {
            this.toastErro(this.$t(`geral.erros.erroGenerico`));
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    validarJustificativa(){
      if(!this.form.justificativa && this.form.revisao > 0) return false;
      return true;
    },
    publicarProcedimento() {
      this.confirmar(
        this.$t('modulos.procedimentos_eletronicos.publicar.titulo'),
        this.$t('modulos.procedimentos_eletronicos.publicar.mensagem')
      ).then(() => {
        if(!this.validarJustificativa()) return this.$emit('justificativa-invalida');
        this.$store.dispatch('Layout/iniciarCarregamento');
        ProcedimentoEletronicoService.publicar(this.form.id)
          .then(() => {
            this.toastSucesso(
              this.$t(`modulos.procedimentos_eletronicos.publicar.sucesso`)
            );
            this.$router.push({ name: 'procedimentos-eletronicos' });
          })
          .catch(() => {
            this.toastErro(this.$t(`geral.erros.erroGenerico`));
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    confirmacaoSalvar(acao) {
      this.salvar(acao);
    },
    async salvar(acao) {
      if (this.tabela.dados.length == 0)
        return this.toastErro(
          this.$t(
            `modulos.procedimentos_eletronicos.tabela_registro.tabela_erro`
          )
        );
      this.$store.dispatch('Layout/iniciarCarregamento');
      await ProcedimentoEletronicoService.salvarOrdenacaoTabelaRegistro(
        this.id,
        this.tabela.dados
      )
        .then(() => {
          this.toastSucesso(
            this.$t(
              `modulos.procedimentos_eletronicos.tabela_registro.tabela_salva`
            )
          );
          this.listarRegistros();
          this.$emit('modificar-formulario', false);

          if (acao === 'sair') {
            this.$router.push({ name: 'procedimentos-eletronicos' });
          }
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    movimentarItem(direcao) {
      const incremento = direcao === 'cima' ? -1 : 1;

      let itensSelecionados = [...this.tabela.selecionados];
      let itensSelecionadosComIndex = itensSelecionados.map((item) => {
        return {
          ...item,
          index: this.tabela.dados.indexOf(item),
        };
      });

      itensSelecionadosComIndex.sort((a, b) =>
        incremento === -1 ? a.index - b.index : b.index - a.index
      );

      itensSelecionadosComIndex.forEach((item) => {
        const novaLista = [...this.tabela.dados];
        const novoIndex = item.index + incremento;

        if (novoIndex < 0 || novoIndex >= novaLista.length) {
          return;
        }
        const itemListaAntes = novaLista[item.index + incremento];

        novaLista[item.index + incremento] = novaLista[item.index];
        novaLista[item.index] = itemListaAntes;
        this.tabela.dados = [...novaLista];
        this.valido = true;
      });
    },
    abrirNovo() {
      this.$emit('abrir-formulario');
    },
    abrirEditar(item) {
      this.$emit('abrir-formulario', item.id);
    },
    cancelar() {
      this.$router.push({ name: 'procedimentos-eletronicos' });
    },
  },
};
</script>
