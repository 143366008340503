import _ from 'lodash';
import { DropdownModel } from '../geral/DropdownModel';

export class ProcedimentoEletronicoModel {
  constructor({
    id,
    nome,
    notasCertificado = '',
    participantes = [],
    locaisExecucoes,
    dataUltimaAnaliseCritica,
    dataProximaAnaliseCritica,
    periodoRevisaoMeses = null,
    tipoProcedimento = 1,
    tiposInstrumentos = [],
    tiposCalibracoes = [],
    escopos = [],
    revisaoId = null,
    codigo = '',
    revisaoPublicada = false,
    revisao,
    justificativa = '',
  }) {
    this.id = id;
    this.codigo = codigo;
    this.justificativa = justificativa;
    this.revisaoPublicada = revisaoPublicada;
    this.revisao = revisao;
    this.nome = nome;
    if (locaisExecucoes)
      this.locaisExecucoesIds = locaisExecucoes?.map((el) => {
        return el.id;
      });
    this.dataUltimaAnaliseCritica = dataUltimaAnaliseCritica;
    this.dataProximaAnaliseCritica = dataProximaAnaliseCritica;
    this.periodoRevisaoMeses = periodoRevisaoMeses;
    this.tipoProcedimento = tipoProcedimento;
    this.participantes = participantes;
    if (participantes) {
      this.participantes = participantes.map((p) => {
        return {
          participante: new DropdownModel(p.participante, 'codigo'),
          setor: p.setor != null ? new DropdownModel(p.setor, 'codigo') : null,
        };
      });
    }

    this.tiposInstrumentos = new DropdownModel(tiposInstrumentos);
    this.tiposCalibracoes = tiposCalibracoes.map((el) => {
      return el.id;
    });
    this.opcoesTiposCalibracoes = new DropdownModel(tiposCalibracoes);
    this.escopos = new DropdownModel(escopos);
    this.notasCertificado = !notasCertificado ? '' : notasCertificado;
    this.revisaoId = revisaoId;
  }

  get request() {
    let retorno = _.cloneDeep(this);
    retorno.tiposCalibracoes = this.tiposCalibracoes.map((el) => {
      return { tipoCalibracaoId: el };
    });
    retorno.tiposInstrumentos = this.tiposInstrumentos.map((el) => {
      return { tipoInstrumentoId: el.value };
    });
    retorno.escopos = this.escopos.map((el) => {
      return { escopoId: el.value };
    });
    retorno.participantes = this.participantes.map((el) => {
      return {
        participanteId: el.participante.value,
        setorId: el.setor?.value,
      };
    });
    return retorno;
  }
}
