var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex mb-8"},[_c('div',{staticClass:"titulo-pagina"},[_vm._v(" "+_vm._s(_vm.tituloFormulario)+" ")])]),_c('v-form',{ref:"form",staticClass:"row",attrs:{"disabled":_vm.ehRevisao || !_vm.podeEditar}},[_c('input-text',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.nome'
        ),"obrigatorio":""},model:{value:(_vm.form.nome),callback:function ($$v) {_vm.$set(_vm.form, "nome", $$v)},expression:"form.nome"}}),_c('input-formula',{ref:"inputFormula",staticClass:"col-12 col-md-3",attrs:{"sem-filtro-quantidade-de-variaveis":"","somente-mensurando":true,"label":_vm.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.formula_mensurando'
        ),"multiplos":false,"prevent-acoes":"","obrigatorio":""},on:{"prevent-abrir-modal":_vm.abrirModalFormula},model:{value:(_vm.form.formulaMensurandoId),callback:function ($$v) {_vm.$set(_vm.form, "formulaMensurandoId", $$v)},expression:"form.formulaMensurandoId"}}),_c('input-unidade-medida',{staticClass:"col-12 col-md-2",attrs:{"label":_vm.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.unidade_medida'
        ),"multiplos":false,"obrigatorio":""},model:{value:(_vm.form.unidadeMedidaId),callback:function ($$v) {_vm.$set(_vm.form, "unidadeMedidaId", $$v)},expression:"form.unidadeMedidaId"}}),_c('v-form',{ref:"amplitudeForm",staticClass:"col-12 col-md-4 d-flex align-top",attrs:{"disabled":_vm.ehRevisao}},[_c('input-text',{staticClass:"flex-fill",attrs:{"label":_vm.$t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.amplitude'
          ),"placeholder":_vm.$t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.amplitude_min'
          ),"obrigatorio":"","regras-personalizadas":_vm.regraAmplitude(),"type":"number"},on:{"input":_vm.validarAmplitude},model:{value:(_vm.form.amplitudeMinima),callback:function ($$v) {_vm.$set(_vm.form, "amplitudeMinima", $$v)},expression:"form.amplitudeMinima"}}),_c('span',{staticClass:"mx-2 mt-11"},[_vm._v(_vm._s(_vm.$t('geral.titulos.a')))]),_c('input-text',{staticClass:"flex-fill mt-5",attrs:{"placeholder":_vm.$t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.amplitude_max'
          ),"obrigatorio":"","regras-personalizadas":_vm.regraAmplitude(),"type":"number"},on:{"input":_vm.validarAmplitude},model:{value:(_vm.form.amplitudeMaxima),callback:function ($$v) {_vm.$set(_vm.form, "amplitudeMaxima", $$v)},expression:"form.amplitudeMaxima"}})],1),_c('input-incerteza',{ref:"inputIncerteza",staticClass:"col-12 col-md-4",attrs:{"formula":_vm.form.formulaMensurandoId,"apenas-publicados":"","apenas-com-revisao":"","label":_vm.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.incerteza'
        ),"titulo-modal":_vm.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.incerteza'
        ),"obrigatorio":"","disabled":!_vm.form.formulaMensurandoId,"prevent-acoes":""},on:{"prevent-abrir-modal":_vm.abrirModalIncerteza,"prevent-remover-opcao":_vm.removerOpcaoIncerteza},model:{value:(_vm.form.incertezasIds),callback:function ($$v) {_vm.$set(_vm.form, "incertezasIds", $$v)},expression:"form.incertezasIds"}}),_c('input-instrumento-padrao',{staticClass:"col-12 col-md-6",attrs:{"formula":_vm.form.formulaMensurandoId,"unidade-medida-id":_vm.form.unidadeMedidaId?.value,"label":_vm.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.instrumentos_padroes'
        ),"disabled":!_vm.form.formulaMensurandoId || !_vm.form.unidadeMedidaId},model:{value:(_vm.form.instrumentosPadroes),callback:function ($$v) {_vm.$set(_vm.form, "instrumentosPadroes", $$v)},expression:"form.instrumentosPadroes"}}),_c('input-select',{staticClass:"col-12 col-md-2",attrs:{"options":_vm.opcoesTiposCalibracoes,"label":_vm.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.tipo_calibracao'
        ),"placeholder":_vm.$t('geral.inputs.nao_informado'),"chips":"","multiplos":""},model:{value:(_vm.form.tiposCalibracoesIds),callback:function ($$v) {_vm.$set(_vm.form, "tiposCalibracoesIds", $$v)},expression:"form.tiposCalibracoesIds"}}),_c('input-procedimento',{staticClass:"col-12 col-md-3",attrs:{"multiplos":"","label":_vm.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.procedimento'
        ),"placeholder":_vm.$t('geral.inputs.selecione')},model:{value:(_vm.form.procedimentos),callback:function ($$v) {_vm.$set(_vm.form, "procedimentos", $$v)},expression:"form.procedimentos"}}),_c('input-formula',{ref:"inputFormulaCorrecaoSmc",staticClass:"col-12 col-md-3",attrs:{"sem-filtro-quantidade-de-variaveis":"","formula-mensurando-id":_vm.form.formulaMensurandoId?.value,"disabled":!_vm.form.formulaMensurandoId,"label":_vm.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.formula_arredondamento_smc'
        ),"titulo-modal":_vm.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.formula_arredondamento_smc'
        ),"multiplos":false},model:{value:(_vm.form.formulaCorrecaoSmcId),callback:function ($$v) {_vm.$set(_vm.form, "formulaCorrecaoSmcId", $$v)},expression:"form.formulaCorrecaoSmcId"}}),_c('input-select',{staticClass:"col-12 col-md-3",attrs:{"options":_vm.opcoesArredondamentoSMC,"label":_vm.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.arredondamento_smc'
        ),"placeholder":_vm.$t('geral.inputs.selecione'),"obrigatorio":_vm.arredondamentoSMCEhObrigatorio},model:{value:(_vm.form.arredondamentoSMCVariavel),callback:function ($$v) {_vm.$set(_vm.form, "arredondamentoSMCVariavel", $$v)},expression:"form.arredondamentoSMCVariavel"}}),_c('input-campos-adicionais',{staticClass:"col-12 col-md-3",attrs:{"tipo-instrumentos":_vm.tipoInstrumentos,"label":_vm.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.campos_adicionais'
        ),"placeholder":_vm.$t('geral.inputs.selecione')},model:{value:(_vm.form.camposAdicionais),callback:function ($$v) {_vm.$set(_vm.form, "camposAdicionais", $$v)},expression:"form.camposAdicionais"}}),_c('input-radio',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t('modulos.procedimentos_eletronicos.tabela_registro.formulario.eh_tabela_auxiliar'),"options":_vm.opcoes.boleano,"divider":false},model:{value:(_vm.form.flagTabelaAuxiliar),callback:function ($$v) {_vm.$set(_vm.form, "flagTabelaAuxiliar", $$v)},expression:"form.flagTabelaAuxiliar"}}),_c('input-select',{staticClass:"col-12 col-md-3",attrs:{"options":_vm.opcoesTabelaAuxiliar,"label":_vm.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.tabela_auxiliar'
        ),"disabled":_vm.form.flagTabelaAuxiliar,"placeholder":_vm.$t('geral.inputs.selecione')},model:{value:(_vm.form.tabelaAuxiliarId),callback:function ($$v) {_vm.$set(_vm.form, "tabelaAuxiliarId", $$v)},expression:"form.tabelaAuxiliarId"}}),_c('input-select',{staticClass:"col-12 col-md-3",attrs:{"options":_vm.opcoesPontosCalibracao,"label":_vm.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.pontos_calibracao'
        ),"placeholder":_vm.$t('geral.inputs.selecione'),"obrigatorio":""},model:{value:(_vm.form.pontosCalibracao),callback:function ($$v) {_vm.$set(_vm.form, "pontosCalibracao", $$v)},expression:"form.pontosCalibracao"}}),_c('input-radio',{staticClass:"col-12 col-md-3",attrs:{"label":_vm.$t('modulos.procedimentos_eletronicos.tabela_registro.formulario.apresentar_certificado'),"options":_vm.opcoes.boleano,"divider":false},model:{value:(_vm.form.apresentarCertificado),callback:function ($$v) {_vm.$set(_vm.form, "apresentarCertificado", $$v)},expression:"form.apresentarCertificado"}})],1),_c('tabela-dinamica',{ref:"tabelaDinamica",staticClass:"mt-16",attrs:{"form":_vm.form,"unidade-medida-pai":_vm.form.unidadeMedidaId},on:{"validar-tabela":_vm.validarFormulario}}),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"d-flex align-center justify-end"},[_c('botao-padrao',{staticClass:"mr-2",attrs:{"outlined":"","color":"secondary"},on:{"click":_vm.cancelar}},[_c('v-icon',[_vm._v("$mdiCancel")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.cancelar'))+" ")],1),(!_vm.ehRevisao)?_c('botao-padrao',{attrs:{"disabled":!_vm.podeEditar},on:{"click":_vm.confirmacaoSalvar}},[_c('v-icon',[_vm._v("$mdiContentSaveOutline")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.salvar'))+" ")],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }