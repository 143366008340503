<template>
  <div>
    <formulario-tabela-registro
      v-if="formularioTabelaRegistroAberto"
      ref="formularioTabelaRegistro"
      :opcoes-tipos-calibracoes="form.opcoesTiposCalibracoes"
      :opcoes-tipos-tabelas="form.opcoesTabelaAuxiliar"
      :id-procedimento-eletronico="form.id"
      @fechar-formulario="fecharFormularioTabelaRegistro"
      @modificar-formulario="modificarFormulario"
    />
    <template v-if="!formularioTabelaRegistroAberto">
      <v-row class="mb-2">
        <v-col
          cols="12"
          class="d-flex align-center justify-space-between"
        >
          <span class="titulo-pagina">
            {{ tituloFormulario }}
          </span>
          <botao-padrao
            v-if="form.revisao"
            outline
            disabled
          >
            {{ revisao }}
          </botao-padrao>
        </v-col>
      </v-row>
      <v-tabs
        v-model="indiceAba"
        class="abas-padrao"
        background-color="primary"
        color="primary"
        @change="verificarSeTemDadoNaoSalvo"
      >
        <v-tab>
          {{
            $t(
              'modulos.procedimentos_eletronicos.formulario.abas.dados_procedimento'
            )
          }}
        </v-tab>

        <v-tooltip
          v-if="!form.id"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              class="v-tab"
              v-bind="attrs"
              v-on="!form.id ? on : null"
              @click="salvarClickAba"
            >
              <div
                class="v-tab"
                v-bind="attrs"
                v-on="!form.id ? on : null"
              >
                {{
                  $t(
                    'modulos.procedimentos_eletronicos.formulario.abas.tabela_registro'
                  )
                }}
              </div>
            </div>
          </template>
          <span>
            {{
              $t(
                'modulos.procedimentos_eletronicos.formulario.abas.clique_salvar_continuar'
              )
            }}
          </span>
        </v-tooltip>

        <v-tab v-else>
          {{
            $t(
              'modulos.procedimentos_eletronicos.formulario.abas.tabela_registro'
            )
          }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="indiceAbaComponente">
        <v-tab-item eager>
          <dados-procedimento
            ref="dados-procedimento"
            :form="form"
            :qtd-participantes="qtdParticipantes"
            @modificar-formulario="modificarFormulario"
          />
        </v-tab-item>
        <v-tab-item>
          <tabela-registro
            :id="ehRevisao || ehRevisaoNaoPublicada ? id : form.revisaoId"
            ref="tabelaRegistro"
            :form="form"
            @abrir-formulario="abrirFormularioTabelaRegistro"
            @modificar-formulario="modificarFormulario"
            @justificativa-invalida="onJustificativaInvalida"
          />
        </v-tab-item>
      </v-tabs-items>

      <template v-if="!indiceAba">
        <v-divider class="my-5" />
        <div class="d-flex align-center justify-end">
          <botao-padrao
            v-if="ehRevisao"
            outlined
            color="secondary"
            @click="voltarPagina"
          >
            <v-icon>$mdiArrowLeft</v-icon>
            {{ $t('geral.botoes.voltar') }}
          </botao-padrao>
          <botao-padrao
            v-else
            outlined
            color="secondary"
            @click="cancelar"
          >
            <v-icon>$mdiCancel</v-icon>
            {{ $t('geral.botoes.cancelar') }}
          </botao-padrao>
          <botao-padrao
            v-if="!ehRevisao"
            sem-capitalize
            class="mx-2"
            @click="confirmacaoSalvar('continuar')"
          >
            <v-icon>$mdiContentSaveMoveOutline</v-icon>
            {{ $t('geral.botoes.salvar_continuar') }}
          </botao-padrao>
          <botao-padrao
            v-if="!ehRevisao"
            sem-capitalize
            class="mr-2"
            @click="confirmacaoSalvar('sair')"
          >
            <v-icon>$mdiContentSaveOutline</v-icon>
            {{ $t('geral.botoes.salvar_sair') }}
          </botao-padrao>
          <botao-padrao
            v-if="!ehRevisao && !!form.id"
            sem-capitalize
            @click="verificarPublicacaoProcedimento"
          >
            <v-icon>$mdiPublish</v-icon>
            {{ $t('geral.botoes.publicar') }}
          </botao-padrao>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import ProcedimentoEletronicoService from '@/common/services/cadastros/ProcedimentoEletronicoService';
import DadosProcedimento from './components/DadosProcedimento';
import TabelaRegistro from './components/TabelaRegistro';
import FormularioTabelaRegistro from './components/FormularioTabelaRegistro';
import { ProcedimentoEletronicoModel } from '@/common/models/cadastros/ProcedimentoEletronicoModel';
import UsuarioService from '@common/services/cadastros/UsuarioService';
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import _ from 'lodash';
import helpers from '@common/utils/helpers';

export default {
  components: {
    DadosProcedimento,
    TabelaRegistro,
    FormularioTabelaRegistro,
  },
  props: {
    id: { type: String, default: '' },
  },
  data() {
    return {
      dadosNaoSalvos: false,
      trocaAbaRegistro: false,
      valido: false,
      formularioTabelaRegistroAberto: false,
      indiceAba: 0,
      qtdParticipantes: 0,
      primeiraVez: true,
      indiceAbaComponente: 0,
      form: new ProcedimentoEletronicoModel({}),
      watchers: {
        escopo: null,
      },
    };
  },
  computed: {
    revisao: function () {
      return `${this.$t(
        'modulos.procedimentos_eletronicos.formulario.revisao'
      )}: ${this.form.revisao}`;
    },
    tituloFormulario() {
      let procedimentoCriadoSemTabela =
        this.$route.name === 'procedimento-eletronico-novo' &&
        (this.id || this.form.id);
      if (procedimentoCriadoSemTabela)
        return this.$t(
          'modulos.procedimentos_eletronicos.titulos.novo_sem_tabela',
          {
            codigo: this.form.codigo,
          }
        );

      if (this.$route.name === 'procedimento-eletronico-revisao')
        return this.$t('modulos.procedimentos_eletronicos.titulos.visualizar', {
          codigo: this.form.codigo,
        });

      if (this.id || this.form.id)
        return this.$t('modulos.procedimentos_eletronicos.titulos.editar', {
          codigo: this.form.codigo,
        });
      return this.$t('modulos.procedimentos_eletronicos.titulos.novo');
    },
    tooltipBotaoSalvar() {
      if (this.valido) return '';

      return this.$t(
        'modulos.procedimentos_eletronicos.validacoes.formulario_invalido'
      );
    },
    ehRevisao() {
      return this.$route.name === 'procedimento-eletronico-revisao';
    },
    ehRevisaoNaoPublicada() {
      return (
        this.$route.name === 'procedimento-eletronico-revisao-nao-publicada'
      );
    },
    podeEditar() {
      return !this.ehRevisao && !this.form.revisaoPublicada;
    },
  },
  watch: {
    id() {
      if (this.id) this.buscar(this.id);
    },
  },
  async mounted() {
    await this.buscarParticipantes();
    if (this.id && this.$route.name === 'procedimento-eletronico-editar') {
      await this.buscar(this.id);
    }

    if (this.id && this.$route.name === 'procedimento-eletronico-revisao') {
      await this.buscarPorRevisao(this.id);
    }

    if (
      this.id &&
      this.$route.name === 'procedimento-eletronico-revisao-nao-publicada'
    ) {
      await this.buscarPorRevisao(this.id);
    }
    if (this.id) {
      helpers.redirecionarSemPermissao(
        this,
        'ProcedimentoEletronico',
        'Editar'
      );
    } else this.definirWatchers();

    helpers.redirecionarSemPermissao(this, 'ProcedimentoEletronico', 'Inserir');
    this.$refs['dados-procedimento'].buscarDependentesEscopo();

    this.$watch(
      'form',
      function () {
        if (this.$refs['dados-procedimento']) {
          if (!this.primeiraVez) {
            this.dadosNaoSalvos = true;
          }
        }
        this.primeiraVez = false;
      },
      { deep: true }
    );

    // this.$watch('form.escopos', function (o, n) {
    //   if (_.isEqual(o, n)) return;
    //   this.form.tiposCalibracoes = [];
    //   this.form.locaisExecucoesIds = [];
    //   this.$refs['dados-procedimento'].buscarDependentesEscopo();
    // });

    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    definirWatchers() {
      if (this.watchers.escopo) this.watchers.escopo();

      this.watchers.escopo = this.$watch('form.escopos', function (o, n) {
        if (_.isEqual(o, n)) return;
        this.form.tiposCalibracoes = [];
        this.form.locaisExecucoesIds = [];

        this.$refs['dados-procedimento'].buscarDependentesEscopo();
      });
    },
    mostrarIdInvalido() {
      this.toastErro(
        this.$t('modulos.procedimentos_eletronicos.erros.id_invalido')
      );
    },

    buscar: async function (id) {
      this.$store.dispatch('Layout/iniciarCarregamento');

      try {
        const response = await ProcedimentoEletronicoService.buscar(
          this.id || id
        );

        this.form = new ProcedimentoEletronicoModel(response.data);
      } catch (error) {
        this.mostrarIdInvalido();
      } finally {
        this.$store.dispatch('Layout/terminarCarregamento');
        this.definirWatchers();
        if (this.trocaAbaRegistro) {
          this.indiceAba = 1;
          this.indiceAbaComponente = 1;
        }
      }
    },
    buscarPorRevisao: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await ProcedimentoEletronicoService.buscarPorRevisao(this.id)
        .then((res) => {
          this.form = new ProcedimentoEletronicoModel(res.data);
        })
        .catch(() => {
          this.mostrarIdInvalido();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    async buscarParticipantes() {
      const { id } = this.$store.getters['Autenticacao/usuario'];
      this.$store.dispatch('Layout/iniciarCarregamento');
      await UsuarioService.buscar(id)
        .then((res) => {
          const { participantes } = res.data;
          this.qtdParticipantes = participantes.length;
          this.form.participantes = participantes.map((d) => {
            return {
              participante: new DropdownModel(d.participante),
              setor: null,
            };
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    onJustificativaInvalida(){
      this.indiceAba = 0;
      this.indiceAbaComponente = 0;
      setTimeout(() => this.$refs['dados-procedimento']?.obrigarPreencherJustificativa(), 500);
    },
    verificarPublicacaoProcedimento: function () {
      if(this.form.revisao > 0 && !this.form.justificativa)
        return this.$refs['dados-procedimento']?.obrigarPreencherJustificativa();

      this.publicarProcedimento(this.form.id);
    },
    publicarProcedimento(id) {
      this.confirmar(
        this.$t('modulos.procedimentos_eletronicos.publicar.titulo'),
        this.$t('modulos.procedimentos_eletronicos.publicar.mensagem')
      ).then(() => {
        if (!this.$refs['dados-procedimento'].$refs.form.validate()) return;
        this.$store.dispatch('Layout/iniciarCarregamento');
        ProcedimentoEletronicoService.salvar(this.form)
          .then(() => {
            ProcedimentoEletronicoService.publicar(id)
              .then(() => {
                this.toastSucesso(
                  this.$t(`modulos.procedimentos_eletronicos.publicar.sucesso`)
                );
                this.$router.push({ name: 'procedimentos-eletronicos' });
              })
              .finally(() => {
                this.$store.dispatch('Layout/terminarCarregamento');
              });
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    salvarClickAba() {
      this.salvar('continuar');
      this.trocaAbaRegistro = true;
    },
    confirmacaoSalvar: async function (acao) {
      this.$refs['dados-procedimento']?.podeJustificativaNula();
      await this.$nextTick();
      this.salvar(acao);
    },
    salvar: async function (acao) {
      if (!this.$refs['dados-procedimento'].$refs.form.validate()) {
        throw new Error();
      }
      this.$store.dispatch('Layout/iniciarCarregamento');
      try {
        const response = await ProcedimentoEletronicoService.salvar(this.form);

        if (!this.form.id && acao === 'continuar') {
          this.toastSucesso(
            this.$t(`modulos.procedimentos_eletronicos.cadastro_sucesso`)
          );
          this.dadosNaoSalvos = false;
          this.primeiraVez = true;
          await this.$router.push({
            name: 'procedimento-eletronico-editar',
            params: { id: response.data },
          });
          return;
        } else {
          this.toastSucesso(
            this.$t(`modulos.procedimentos_eletronicos.edicao_sucesso`)
          );
        }

        if (acao === 'sair') {
          this.$router.push({ name: 'procedimentos-eletronicos' });
        } else if (acao === 'continuar') {
          this.buscar();
          this.valido = false;
          this.dadosNaoSalvos = false;
          this.indiceAba = 1;
          this.indiceAbaComponente = 1;
        }
      } finally {
        this.$store.dispatch('Layout/terminarCarregamento');
      }
    },
    cancelar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'procedimentos-eletronicos' });
      });
    },
    voltarPagina() {
      this.$router.back();
    },
    abrirFormularioTabelaRegistro(id) {

      this.formularioTabelaRegistroAberto = true;
      this.$nextTick(() => {
        this.$refs.formularioTabelaRegistro.abrirFormulario(
          this.id,
          id,
          this.form.tiposInstrumentos,
          this.form.revisaoId,
          this.podeEditar
        );
      });
    },
    fecharFormularioTabelaRegistro() {
      this.formularioTabelaRegistroAberto = false;
      this.$nextTick(() => {
        this.$refs.tabelaRegistro.listarRegistros();
        this.$refs['dados-procedimento'].buscarDependentesEscopo();
      });
    },
    async verificarSeTemDadoNaoSalvo() {
      if (this.dadosNaoSalvos) {
        try {
          if (this.indiceAba == 0) {
            await this.$refs['tabelaRegistro'].salvar();
          } else {
            await this.salvar();
          }
          this.dadosNaoSalvos = false;
          this.indiceAbaComponente = this.indiceAba;
        } catch (error) {
          this.indiceAba = this.indiceAbaComponente;
        }
      } else {
        this.dadosNaoSalvos = false;
        this.indiceAbaComponente = this.indiceAba;
      }
    },
    modificarFormulario(booleano = true) {
      this.dadosNaoSalvos = booleano;
    },
  },
};
</script>
