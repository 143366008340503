export class DropdownCamposAdicionaisModel {
  constructor(lista, texto = 'nome', valorInput = 'valorInput') {
    if (Array.isArray(lista))
      return lista.map((el) => {
        let novoObj = {};
        novoObj.value = el.id;
        novoObj.text = el[texto];
        novoObj.valorInput = el[valorInput];
        return novoObj;
      });
    return {
      value: lista.id,
      text: lista[texto],
      valorInput: lista[valorInput],
    };
  }
}
