import _ from 'lodash';
import { DropdownModel } from '../geral/DropdownModel';
import { DropdownCamposAdicionaisModel } from '../geral/DropdownCamposAdicionaisModel';
import { DropdownProcedimento } from '../geral/DropdownProcedimento';
import helpers from '@common/utils/helpers';
import { TipoColunaConst } from '@const/TipoColunaConst';
export class ProcedimentoEletronicoTabelaRegistroModel {
  constructor({
    id,
    nome,
    amplitudeMinima = null,
    amplitudeMaxima = null,
    grandezaFisicaId = null,
    unidadeMedida = null,
    tiposCalibracoesIds = [],
    procedimentos = null,
    instrumentosPadroes = [],
    colunas = [],
    formulaMensurando = null,
    linhas = [],
    incertezas = [],
    camposAdicionais = [],
    arredondamentoSMC = null,
    variavelResolucao = null,
    formulaCorrecaoSmc = null,
    flagTabelaAuxiliar = true,
    apresentarCertificado = true,
    pontosCalibracao = "Instrumento",
    tabelaAuxiliar = null,
  }) {
    this.id = id;
    this.nome = nome;
    this.incertezasIds = new DropdownModel(incertezas);

    this.formulaMensurandoId = null;
    if (formulaMensurando)
      this.formulaMensurandoId = new DropdownModel(formulaMensurando);

    this.formulaCorrecaoSmcId = null;
    if (formulaCorrecaoSmc)
      this.formulaCorrecaoSmcId = new DropdownModel(formulaCorrecaoSmc);

    this.flagTabelaAuxiliar = flagTabelaAuxiliar;
    this.apresentarCertificado = apresentarCertificado;
    this.pontosCalibracao = pontosCalibracao;
    this.tabelaAuxiliar = tabelaAuxiliar;

    this.tabelaAuxiliarId = null;
    if (tabelaAuxiliar)
      this.tabelaAuxiliarId = this.tabelaAuxiliar.id;

    this.pontosCalibracaoId = null;
    if (pontosCalibracao)
      this.pontosCalibracaoId = new DropdownModel(pontosCalibracao);

    this.amplitudeMinima = amplitudeMinima;
    this.amplitudeMaxima = amplitudeMaxima;
    this.grandezaFisicaId = grandezaFisicaId;
    this.unidadeMedidaId = unidadeMedida;
    if (unidadeMedida) this.unidadeMedidaId = new DropdownModel(unidadeMedida);
    this.procedimentos = null;
    if (procedimentos)
      this.procedimentos = new DropdownProcedimento(procedimentos);
    this.tiposCalibracoesIds = tiposCalibracoesIds;

    this.instrumentosPadroes = instrumentosPadroes.map((el) => {
      return {
        id: el.id,
        text: `${el.formulaVariavel.nome} = ${el.instrumentoViewModelPadrao.codigo} - ${el.instrumentoFaixa.descricao}`,
        participanteId: el.participante.id,
        formulaVariavel: new DropdownModel(el.formulaVariavel),
        instrumentoPadrao: {
          ...el.instrumentoViewModelPadrao,
          faixaSelecionada: el.instrumentoFaixa
        }
      };
    });

    this.colunas = [];
    colunas.forEach((coluna) => {
      let indice =
        this.colunas.filter((el) => el.tipoColuna == coluna.tipoColuna).length +
        1;
      if (coluna.unidadeMedida)
        coluna.unidadeMedidaId = new DropdownModel(coluna.unidadeMedida);
      if (coluna.formula) coluna.formulaId = new DropdownModel(coluna.formula);
      if (coluna.variavel) coluna.formulaVariavelId = coluna.variavel.id;
      coluna.indice = indice;
      coluna.indiceAntigo = indice;
      coluna.tipoColunaAntiga = coluna.tipoColuna;
      if(coluna.tabelaAuxiliarColuna)
        coluna.tabelaAuxiliarColunaId = coluna.tabelaAuxiliarColuna.id;
      this.colunas.push(coluna);
    });
    this.linhas = linhas.map((el) => {
      el.identificador = `linha-${helpers.gerarNumeroAleatorio()}`;
      return el;
    });

    this.camposAdicionais = camposAdicionais.map((el) => {
      el.valor = el.valor.includes(',') ? el.valor.split(',') : el.valor;

      return new DropdownCamposAdicionaisModel({
        id: el.campoAdicional.id,
        nome: el.campoAdicional.nome,
        valorInput: el.valor,
      });
    });

    this.arredondamentoSMC = arredondamentoSMC;
    this.variavelResolucaoId = variavelResolucao?.id;

    this.arredondamentoSMCVariavel = arredondamentoSMC;
    if (arredondamentoSMC == 'ResolucaoSMP') {
      this.arredondamentoSMCVariavel = variavelResolucao?.id;
    }
  }

  get request() {
    let retorno = _.cloneDeep(this);
    retorno.unidadeMedidaId = retorno.unidadeMedidaId?.value;
    retorno.instrumentosPadroes = retorno.instrumentosPadroes.map((el) => {
      return {
        participanteId: el.participanteId,
        formulaVariavelId: el.formulaVariavel.value,
        instrumentoPadraoId: el.instrumentoPadrao.id,
        instrumentoFaixaId: el.instrumentoPadrao.faixaSelecionada.id,
      };
    });

    retorno.formulaMensurandoId = retorno.formulaMensurandoId.value;

    if(retorno.tabelaAuxiliar)
        retorno.tabelaAuxiliar = retorno.tabelaAuxiliar.value;

    if(retorno.pontosCalibracaoId)
      retorno.pontosCalibracaoId = retorno.pontosCalibracaoId.value;

    if (retorno.formulaCorrecaoSmcId)
      retorno.formulaCorrecaoSmcId = retorno.formulaCorrecaoSmcId.value;

    retorno.arredondamentoSMC =
      retorno.arredondamentoSMCVariavel == 'ResolucaoSMC'
        ? 'ResolucaoSMC'
        : 'ResolucaoSMP';
    retorno.variavelResolucaoId = null;
    if (retorno.arredondamentoSMCVariavel != 'ResolucaoSMC')
      retorno.variavelResolucaoId = retorno.arredondamentoSMCVariavel;

    if (retorno.procedimentos)
      retorno.procedimentosIds = retorno.procedimentos.map(
        ({ value }) => value
      );

    if (retorno.incertezasIds)
      retorno.incertezasIds = retorno.incertezasIds.map(({ value }) => value);

    retorno.colunas = retorno.colunas.map((el) => {
      if (el.unidadeMedidaId) el.unidadeMedidaId = el.unidadeMedidaId.value;
      if (el.formulaId) el.formulaId = el.formulaId.value;
      if (el.tipoColuna != TipoColunaConst.RESULTADO) el.formulaId = null;
      if (el.tipoColuna != TipoColunaConst.SMP && el.tipoColuna != TipoColunaConst.REFERENCIA) el.formulaVariavelId = null;
      if (el.tipoColuna != TipoColunaConst.UNIDADE) el.unidadeMedidaId = null;
      if (el.tipoColuna != TipoColunaConst.TABELAAUXILIAR) el.tabelaAuxiliarColunaId = null;
      return el;
    });

    retorno.camposAdicionais = retorno.camposAdicionais.map((el) => {
      if (Array.isArray(el.valorInput)) el.valorInput = el.valorInput.join(',');
      if (el.tipoCampo == 'Data') el.valorInput = el.valorInput.substr(0, 10);
      return {
        campoAdicionalId: el.value,
        valor: el.valorInput,
      };
    });

    return retorno;
  }

  RetornarColuna(
    tipoColuna,
    cabecalho,
    indice,
    formula = null,
    sentido = 'DeCimaParaBaixo',
    variavel = null,
    unidadeMedidaId = null,
    visivel = true
  ) {
    return {
      tipoColuna: tipoColuna,
      tipoColunaAntiga: tipoColuna,
      cabecalho: cabecalho,
      visivel: visivel,
      sentido: sentido,
      unidadeMedidaId: unidadeMedidaId,
      tabelaAuxiliarColunaId: variavel,
      indice: indice,
      indiceAntigo: indice,
      formulaVariavelId: variavel,
      formulaId: formula,
    };
  }
}
