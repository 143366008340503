<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-form
      ref="form"
      :disabled="ehRevisao || !podeEditar"
      class="row"
    >
      <input-text
        v-model="form.nome"
        class="col-12 col-md-3"
        :label="
          $t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.nome'
          )
        "
        obrigatorio
      />
      <input-formula
        ref="inputFormula"
        v-model="form.formulaMensurandoId"
        sem-filtro-quantidade-de-variaveis
        :somente-mensurando="true"
        class="col-12 col-md-3"
        :label="
          $t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.formula_mensurando'
          )
        "
        :multiplos="false"
        prevent-acoes
        obrigatorio
        @prevent-abrir-modal="abrirModalFormula"
      />
      <input-unidade-medida
        v-model="form.unidadeMedidaId"
        class="col-12 col-md-2"
        :label="
          $t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.unidade_medida'
          )
        "
        :multiplos="false"
        obrigatorio
      />
      <v-form
        ref="amplitudeForm"
        class="col-12 col-md-4 d-flex align-top"
        :disabled="ehRevisao"
      >
        <input-text
          v-model="form.amplitudeMinima"
          class="flex-fill"
          :label="
            $t(
              'modulos.procedimentos_eletronicos.tabela_registro.formulario.amplitude'
            )
          "
          :placeholder="
            $t(
              'modulos.procedimentos_eletronicos.tabela_registro.formulario.amplitude_min'
            )
          "
          obrigatorio
          :regras-personalizadas="regraAmplitude()"
          type="number"
          @input="validarAmplitude"
        />
        <span class="mx-2 mt-11">{{ $t('geral.titulos.a') }}</span>
        <input-text
          v-model="form.amplitudeMaxima"
          class="flex-fill mt-5"
          :placeholder="
            $t(
              'modulos.procedimentos_eletronicos.tabela_registro.formulario.amplitude_max'
            )
          "
          obrigatorio
          :regras-personalizadas="regraAmplitude()"
          type="number"
          @input="validarAmplitude"
        />
      </v-form>
      <input-incerteza
        ref="inputIncerteza"
        v-model="form.incertezasIds"
        :formula="form.formulaMensurandoId"
        class="col-12 col-md-4"
        apenas-publicados
        apenas-com-revisao
        :label="
          $t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.incerteza'
          )
        "
        :titulo-modal="
          $t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.incerteza'
          )
        "
        obrigatorio
        :disabled="!form.formulaMensurandoId"
        prevent-acoes
        @prevent-abrir-modal="abrirModalIncerteza"
        @prevent-remover-opcao="removerOpcaoIncerteza"
      />
      <input-instrumento-padrao
        v-model="form.instrumentosPadroes"
        class="col-12 col-md-6"
        :formula="form.formulaMensurandoId"
        :unidade-medida-id="form.unidadeMedidaId?.value"
        :label="
          $t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.instrumentos_padroes'
          )
        "
        :disabled="!form.formulaMensurandoId || !form.unidadeMedidaId"
      />
      <input-select
        v-model="form.tiposCalibracoesIds"
        :options="opcoesTiposCalibracoes"
        class="col-12 col-md-2"
        :label="
          $t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.tipo_calibracao'
          )
        "
        :placeholder="$t('geral.inputs.nao_informado')"
        chips
        multiplos
      />
      <input-procedimento
        v-model="form.procedimentos"
        multiplos
        class="col-12 col-md-3"
        :label="
          $t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.procedimento'
          )
        "
        :placeholder="$t('geral.inputs.selecione')"
      />

      <input-formula
        ref="inputFormulaCorrecaoSmc"
        v-model="form.formulaCorrecaoSmcId"
        sem-filtro-quantidade-de-variaveis
        :formula-mensurando-id="form.formulaMensurandoId?.value"
        :disabled="!form.formulaMensurandoId"
        class="col-12 col-md-3"
        :label="
          $t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.formula_arredondamento_smc'
          )
        "
        :titulo-modal="
          $t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.formula_arredondamento_smc'
          )
        "
        :multiplos="false"
      />
      <input-select
        v-model="form.arredondamentoSMCVariavel"
        :options="opcoesArredondamentoSMC"
        class="col-12 col-md-3"
        :label="
          $t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.arredondamento_smc'
          )
        "
        :placeholder="$t('geral.inputs.selecione')"
        :obrigatorio="arredondamentoSMCEhObrigatorio"
      />
      <input-campos-adicionais
        v-model="form.camposAdicionais"
        :tipo-instrumentos="tipoInstrumentos"
        class="col-12 col-md-3"
        :label="
          $t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.campos_adicionais'
          )
        "
        :placeholder="$t('geral.inputs.selecione')"
      />
      <input-radio
        v-model="form.flagTabelaAuxiliar"
        class="col-12 col-md-3"
        :label="$t('modulos.procedimentos_eletronicos.tabela_registro.formulario.eh_tabela_auxiliar')"
        :options="opcoes.boleano"
        :divider="false"
      />
      <input-select
        v-model="form.tabelaAuxiliarId"
        :options="opcoesTabelaAuxiliar"
        class="col-12 col-md-3"
        :label="
          $t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.tabela_auxiliar'
          )
        "
        :disabled="form.flagTabelaAuxiliar"
        :placeholder="$t('geral.inputs.selecione')"
      />
      <input-select
        v-model="form.pontosCalibracao"
        :options="opcoesPontosCalibracao"
        class="col-12 col-md-3"
        :label="
          $t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.pontos_calibracao'
          )
        "
        :placeholder="$t('geral.inputs.selecione')"
        obrigatorio
      />
      <input-radio
        v-model="form.apresentarCertificado"
        class="col-12 col-md-3"
        :label="$t('modulos.procedimentos_eletronicos.tabela_registro.formulario.apresentar_certificado')"
        :options="opcoes.boleano"
        :divider="false"
      />
    </v-form>

    <tabela-dinamica
      ref="tabelaDinamica"
      class="mt-16"
      :form="form"
      :unidade-medida-pai="form.unidadeMedidaId"
      @validar-tabela="validarFormulario"
    />

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        v-if="!ehRevisao"
        :disabled="!podeEditar"
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import ProcedimentoEletronicoService from '@common/services/cadastros/ProcedimentoEletronicoService';
import GrandezaFisicaService from '@common/services/cadastros/GrandezaFisicaService';
import ProcedimentoService from '@common/services/cadastros/ProcedimentoService';
import FormulaService from '@common/services/cadastros/FormulaService';
import { ProcedimentoEletronicoTabelaRegistroModel } from '@common/models/cadastros/ProcedimentoEletronicoTabelaRegistroModel';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import {
  InputUnidadeMedida,
  InputInstrumentoPadrao,
  InputIncerteza,
  InputFormula,
  InputCamposAdicionais,
  InputProcedimento,
} from '@components/inputs';
import helpers from '@common/utils/helpers';
import TabelaDinamica from './TabelaDinamica.vue';
import { TipoColunaConst } from '@const/TipoColunaConst';
export default {
  components: {
    InputUnidadeMedida,
    InputInstrumentoPadrao,
    TabelaDinamica,
    InputIncerteza,
    InputFormula,
    InputCamposAdicionais,
    InputProcedimento,
  },
  props: {
    opcoesTiposCalibracoes: {
      type: Array,
      default: () => {
        return [];
      },
    },
    idProcedimentoEletronico: { type: String, default: '' },
  },
  data() {
    return {
      podeEditar: true,
      id: null,
      tipoInstrumentos: [],
      revisaoId2: null,
      procedimentoId: null,
      valido: false,
      validoTabela: false,
      indiceAba: 0,
      form: new ProcedimentoEletronicoTabelaRegistroModel({}),
      opcoes: {
        grandeza: [],
        procedimento: [],
        boleano: helpers.BoleanoEnum,
      },
      variaveisFormula: [],
      watchFormula: null,
      watchIncerteza: null,
      opcoesTabelaAuxiliar: [],
    };
  },
  computed: {
    arredondamentoSMCEhObrigatorio: function () {
      return (
        this.form.colunas.filter((el) => el.tipoColuna == TipoColunaConst.SMC)
          .length > 0
      );
    },
    contemRevisoes: function () {
      const padrao = /\/revisao\/\d+/;
      return padrao.test(this.$route.path);
    },
    tabelaAuxiliarNao: function () {
      if(!this.form.flagTabelaAuxiliar) {
        return this.listarTabelasAux()
      }
      return false
    },
    opcoesArredondamentoSMC: function () {
      let opcoes = [
        {
          value: 'ResolucaoSMC',
          text: this.$t(
            'modulos.procedimentos_eletronicos.tabela_registro.resolucao_smc'
          ),
        },
      ];
      opcoes.push(
        ...this.variaveisFormula.map((el) => {
          return {
            value: el.id,
            text: this.$t(
              'modulos.procedimentos_eletronicos.tabela_registro.resolucao_smp',
              { variavel: el.simbolo }
            ),
          };
        })
      );
      return opcoes;
    },
    opcoesPontosCalibracao: function () {
      let opcoes =[
        {
          value: 'Instrumento',
          text: this.$t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.tipos_calibracoes.instrumento'
          ),
        },
        {
          value: 'Unico',
          text: this.$t(
            'modulos.procedimentos_eletronicos.tabela_registro.formulario.tipos_calibracoes.unico'
          ),
        },
      ]
      return opcoes;
    },
    tituloFormulario: function () {
      const { name } = this.$route;
      if (name == 'procedimento-eletronico-revisao') {
        return this.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.titulos.visualizar'
        );
      }
      if (this.id)
        return this.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.titulos.editar'
        );
      return this.$t(
        'modulos.procedimentos_eletronicos.tabela_registro.titulos.novo'
      );
    },
    tooltipBotaoSalvar: function () {
      if (!this.valido)
        return this.$t(
          'modulos.procedimentos_eletronicos.validacoes.formulario_invalido'
        );

      if (this.form.colunas.length == 0)
        return this.$t('modulos.procedimentos_eletronicos.validacoes.coluna');

      if (
        this.form.colunas.filter((el) => el.tipoColuna == TipoColunaConst.SMC)
          .length == 1 ||
        this.form.colunas.filter((el) => el.tipoColuna == TipoColunaConst.SMP)
          .length == 1
      )
        return this.$t(
          'modulos.procedimentos_eletronicos.validacoes.colunas_smp_smc'
        );

      if (this.valido && this.validoTabela) return '';

      return this.$t(
        'modulos.procedimentos_eletronicos.validacoes.tabela_formulario_invalido'
      );
    },
    ehRevisao: function () {
      return this.$route.name === 'procedimento-eletronico-revisao';
    },
  },
  watch: {
    'form.formulaMensurandoId': async function () {
      if (
        typeof this.form.unidadeMedidaId === 'undefined' ||
        !this.form.unidadeMedidaId
      ) {
        await this.selecionarUnidadeMedida();
        this.$forceUpdate();
      }
    },
    'form.flagTabelaAuxiliar': function () {
      if(!this.form.flagTabelaAuxiliar) {
        this.tabelaAuxiliarNao
      } else {
        this.limparTabelaAuxiliar()
      }
    },
  },
  mounted() {
    this.buscarGrandeza();
    this.buscarProcedimento();
  },
  methods: {
    validarFormulario: function () {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if (this.$refs.tabelaDinamica)
        this.validoTabela = this.$refs.tabelaDinamica.$refs.form.validate();
      if (this.validoTabela)
        this.validoTabela = this.form.colunas.length > 0 && this.validoTabela;

      return this.validoTabela && this.valido;
    },
    abrirModalFormula: function () {
      if (this.id) {
        this.$refs.inputFormula.abrirModal();
        return;
      }

      this.confirmarAlterarDados(
        this.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.formula_mensurando'
        )
      ).then(() => {
        this.$refs.inputFormula.abrirModal();
      });
    },
    abrirModalIncerteza: function () {
      if (this.id) {
        this.$refs.inputIncerteza.abrirModal();
        return;
      }

      this.confirmarAlterarDados(
        this.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.incerteza'
        )
      ).then(() => {
        this.$refs.inputIncerteza.abrirModal();
      });
    },
    removerOpcaoIncerteza: function (indice) {
      if (this.id) {
        this.$refs.inputIncerteza.removerOpcao(indice);
        return;
      }

      this.confirmarAlterarDados(
        this.$t(
          'modulos.procedimentos_eletronicos.tabela_registro.formulario.incerteza'
        )
      ).then(() => {
        this.$refs.inputIncerteza.removerOpcao(indice);
      });
    },
    regraAmplitude: function () {
      if (!this.form.amplitudeMaxima || !this.form.amplitudeMinima) return [];

      let regraValor = (v) => {
        let valor = `${v}`;
        let intValue = valor.split('.')[0];
        let decimalValue = valor.split('.')[1];
        if (intValue.length > 10) {
          return this.$t('inputs.rules.maxBetweenDecimalValue', {
            char: 10,
            decimal: 9,
          });
        }

        if (decimalValue && decimalValue.length > 9) {
          return this.$t('inputs.rules.maxBetweenDecimalValue', {
            decimal: 9,
            char: 10,
          });
        }

        return true;
      };

      let regraValorMaior = () =>
        parseFloat(this.form.amplitudeMaxima) >=
        parseFloat(this.form.amplitudeMinima)
          ? true
          : this.$t(
              'inputs.rules.amplitudeMaximaNaoPodeSerMenorQueAmplitudeMinima'
            );
      return [regraValor, regraValorMaior];
    },
    listarTabelasAux() {
      ProcedimentoEletronicoService.listarTabelasAuxiliares(this.revisaoId2)
        .then((res) => {
            this.opcoesTabelaAuxiliar = res.data.map((el) => {
            return {
              value: el.id,
              text: el.nome,
            };
          })

          this.opcoesTabelaAuxiliar.unshift({
          value: null,
          text: 'Selecionar',
        });
      });
    },
    abrirFormulario: async function (
      procedimentoId,
      id,
      tipoInstrumentos,
      revisaoId,
      podeEditar
    ) {
      const urlId = this.$route.params.id;
      this.id = id;
      this.podeEditar = podeEditar;
      this.procedimentoId = procedimentoId;
      if(this.contemRevisoes || this.ehRevisao) {
        this.revisaoId2 = urlId
      } else {
        this.revisaoId2 = revisaoId;
      }

      this.tipoInstrumentos = tipoInstrumentos.map(
        (instrumento) => instrumento?.value
      );
      this.form = new ProcedimentoEletronicoTabelaRegistroModel({});

      if (this.opcoesTiposCalibracoes.length) {
        this.opcoesTiposCalibracoes.forEach((item) => {
          this.form.tiposCalibracoesIds.push(item.value);
        });
      }

      this.$refs.form.resetValidation();
      if (this.watchFormula != null) this.watchFormula();
      if (this.watchIncerteza != null) this.watchIncerteza();

      if (this.id) await this.buscar();
      this.buscarFormulas();

      this.watchFormula = this.$watch('form.formulaMensurandoId', function () {
        this.form.instrumentosPadroes = [];
        this.popularDadosIniciais(true);
        if (!this.form.formulaMensurandoId) return;
        this.buscarFormulas();
      });

      this.watchIncerteza = this.$watch('form.incertezasIds', function () {
        this.popularDadosIniciais();
      });
    },
    buscarFormulas: function () {
      if (!this.form.formulaMensurandoId) return;
      FormulaService.buscar(this.form.formulaMensurandoId?.value).then(
        (res) => {
          this.variaveisFormula = res.data.variaveis;
        }
      );
    },
    buscarGrandeza: function () {
      GrandezaFisicaService.listar({}).then((res) => {
        this.opcoes.grandeza = new DropdownModel(res.data);
      });
    },
    buscarProcedimento: function () {
      ProcedimentoService.listar({}).then((res) => {
        this.opcoes.procedimento = new DropdownModel(res.data.items, 'titulo');
      });
    },
    buscar: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await ProcedimentoEletronicoService.buscarTabelaRegistro(this.id)
        .then((res) => {
          this.form = new ProcedimentoEletronicoTabelaRegistroModel(res.data);
          this.validarFormulario();
        })
        .catch(() => {
          this.toastErro(
            this.$t(
              'modulos.procedimentos_eletronicos.erros.necessario_campo_tabela_auxiliar'
            )
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      if (!this.validarFormulario() || !this.$refs.amplitudeForm.validate()) return
      this.$store.dispatch('Layout/iniciarCarregamento');

      ProcedimentoEletronicoService.salvarTabelaRegistro(
        this.form,
        this.idProcedimentoEletronico
      )
        .then(() => {
          this.toastSucesso(
            this.$t(
              `modulos.procedimentos_eletronicos.tabela_registro.cadastro_sucesso`
            )
          );
          this.$emit('fechar-formulario');
          this.$emit('modificar-formulario');
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$emit('fechar-formulario');
      });
    },

    confirmarAlterarDados: function (campo) {
      return new Promise((resolve, reject) => {
        if (this.form.colunas.length == 0) {
          resolve();
          return;
        }
        this.confirmar(
          this.$t('geral.titulos.atencao'),
          this.$t(
            'modulos.procedimentos_eletronicos.tabela_registro.confirmar_alteracao_dados',
            { campo: campo }
          )
        )
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },

    retornarFuncaoFormulas: async function () {
      let parametros = {
        Page: 1,
        AmountPerPage: 100,
        CustomFormulaPaiId: this.form.formulaMensurandoId.value,
      };

      let f = function () {};

      await FormulaService.listar(parametros)
        .then((res) => {
          f = function (filtro) {
            let formula = res.data.items.find((el) => el.simbolo == filtro);
            if (!formula) return null;
            return {
              value: formula.id,
              text: formula.nome,
            };
          };
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });

      return f;
    },

    popularDadosIniciais: async function (limparIncertezas = false) {
      if (limparIncertezas) this.form.incertezasIds = [];
      const funcao = await this.retornarFuncaoFormulas();

      if (this.id && this.form.colunas.length > 0) {
        this.form.colunas
          .filter((el) => el.tipoColuna == TipoColunaConst.RESULTADO)
          .forEach((coluna) => {
            switch (coluna.formulaId?.text) {
              case 'Erro':
                coluna.formulaId = funcao('erro');
                break;
              case 'Incerteza':
                coluna.formulaId = funcao('incerteza');
                break;
              default:
                coluna.formulaId = null;
                break;
            }
          });

        return;
      }

      this.form.colunas = [];

      if (this.form.incertezasIds.length == 0) return;

      let variavel =
        this.variaveisFormula.length > 0 ? this.variaveisFormula[0].id : null;
      this.form.colunas.push(
        this.form.RetornarColuna(TipoColunaConst.TEXTO, 'Nome', 1)
      );
      this.form.colunas.push(
        this.form.RetornarColuna(
          TipoColunaConst.SMP,
          'SMP1',
          1,
          null,
          'DeCimaParaBaixo',
          variavel
        )
      );
      this.form.colunas.push(
        this.form.RetornarColuna(
          TipoColunaConst.SMP,
          'SMP2',
          2,
          null,
          'DeCimaParaBaixo',
          variavel
        )
      );
      this.form.colunas.push(
        this.form.RetornarColuna(
          TipoColunaConst.SMP,
          'SMP3',
          3,
          null,
          'DeCimaParaBaixo',
          variavel
        )
      );

      if (this.variaveisFormula.length)
        this.form.colunas.push(
          this.form.RetornarColuna(
            TipoColunaConst.RESULTADO,
            'Média',
            1,
            funcao(`mediaSmp${this.variaveisFormula[0].simbolo}`)
          )
        );
      this.form.colunas.push(
        this.form.RetornarColuna(
          TipoColunaConst.RESULTADO,
          'Erro',
          2,
          funcao('erro')
        )
      );
      this.form.colunas.push(
        this.form.RetornarColuna(
          TipoColunaConst.RESULTADO,
          'Incerteza',
          3,
          funcao('incerteza')
        )
      );
      this.form.colunas.push(
        this.form.RetornarColuna(
          TipoColunaConst.RESULTADO,
          'Veff',
          4,
          funcao('veff')
        )
      );
      this.form.colunas.push(
        this.form.RetornarColuna(
          TipoColunaConst.RESULTADO,
          'Fator K',
          5,
          funcao('fatork')
        )
      );

      this.form.linhas.push({
        identificador: `linha-${helpers.gerarNumeroAleatorio()}`,
        valorReferencia: 0,
        valorTexto1: '',
        valorTexto2: '',
        valorTexto3: '',
        valorTexto4: '',
        valorTexto5: '',
      });
    },
    selecionarUnidadeMedida: async function () {
      await FormulaService.buscar(this.form.formulaMensurandoId.value).then(
        (res) => {
          this.form.unidadeMedidaId = new DropdownModel(res.data.unidadeMedida);
        }
      );
    },
    validarAmplitude() {
      if (!this.form.amplitudeMinima && !this.form.amplitudeMaxima) return;
      this.$refs.amplitudeForm.validate();
    },
    limparTabelaAuxiliar() {
      this.form.tabelaAuxiliarId = null
    }
  },
};
</script>