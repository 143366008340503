<template>
  <v-form ref="formTabela" :disabled="ehRevisao">
    <v-simple-table dense class="mt-3 tabela-dados-procedimentos-eletronicos">
      <thead>
        <tr>
          <th scope="col" :width="'50px'" />
          <th
            v-for="(coluna, index) in tabela.colunas"
            :key="index"
            :width="coluna.width"
            scope="col"
          >
            {{ coluna.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in form" :key="index">
          <td scope="col">
            <dropdown-padrao text color="secondary">
              <template #botao>
                <v-icon> $dotsVertical </v-icon>
              </template>
              <div>
                <botao-padrao
                  text
                  color="secondary"
                  :disabled="ehRevisao"
                  @click="removerItemDaTabela(index)"
                >
                  <v-icon>$mdiTrashCanOutline</v-icon>
                  {{ $t('geral.botoes.excluir_linha') }}
                </botao-padrao>
              </div>
            </dropdown-padrao>
          </td>
          <td>
            <div class="d-flex align-center">
              <span>{{ item.participante.text }}</span>
            </div>
          </td>
          <!-- Setor -->
          <td>
            <input-setor
              v-model="item.setor"
              :multiplos="false"
              sem-label
              flat
              solo
              class="flex-fill input-tabela mt-2"
              background-color="transparent"
              :participante-id="item.participante.value"
            />
          </td>
          <td />
        </tr>

        <tr>
          <td colspan="4" style="border-top: 1px solid #d5d6d9">
            <botao-padrao
              text
              color="secondary"
              :disabled="temTodosOsParticipantes || ehRevisao"
              @click="abrirModal"
            >
              <v-icon> $plus </v-icon>
              {{
                $t(
                  'modulos.procedimentos_eletronicos.tabela_dinamica.botoes.adicionar_linha'
                )
              }}
            </botao-padrao>
          </td>
        </tr>
      </tbody>
      <tbody />
    </v-simple-table>
    <modal-padrao
      ref="modal-input"
      :max-width="1000"
      :titulo="$t('componentes.input_participantes.titulo_modal')"
      :titulo-ok="$t('geral.botoes.adicionar')"
      @ok="aplicarSelecionados"
    >
      <usuarios-procedimento-modal
        v-model="participanteSelecionado"
        refs="modal"
        :key="componentKey"
        :multiplos="multiplos"
      />
    </modal-padrao>
  </v-form>
</template>
<script>
import InputSetor from '@/components/inputs/especificos/InputSetor';
import UsuariosProcedimentoModal from './modal/UsuariosProcedimentoModal.vue';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import { cloneDeep } from 'lodash';
export default {
  name: 'TabelaDadosProcedimento',
  components: { InputSetor, UsuariosProcedimentoModal },
  props: {
    form: {},
    qtdParticipantes: { type: Number, default: 0 },
    ehRevisao: { type: Boolean, default: false },
  },
  data() {
    return {
      componentKey: 0,
      multiplos: true,
      participanteSelecionado: [],
      opcoes: {
        setores: [],
      },
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t(
              'modulos.procedimentos_eletronicos.formulario.tabela.participantes'
            ),
            sortable: false,
            width: '300px',
          },
          {
            value: 'setor',
            text: this.$t(
              'modulos.procedimentos_eletronicos.formulario.tabela.setor'
            ),
            width: '300px',
            sortable: false,
          },
          {
            value: '',
            width: '780px',
            sortable: false,
          },
        ],
      },
    };
  },
  computed: {
    temTodosOsParticipantes: function () {
      return this.form.length == this.qtdParticipantes;
    },
  },
  methods: {
    adicionarLinha: function (participante) {
      if (participante.length) {
        participante.forEach((item) => {
          this.form.push(item);
        });
      }
    },
    removerItemDaTabela: function (index) {
      this.form.splice(index, 1);
    },
    abrirModal: function () {
      this.$refs['modal-input'].abrirModal();
      this.componentKey++;
      const participanteClonado = cloneDeep(this.form);
      this.participanteSelecionado = participanteClonado.map(
        ({ participante }) => {
          return {
            id: participante.value,
            participante: { id: participante.value, nome: participante.text },
            setor: null,
          };
        }
      );
    },

    aplicarSelecionados: function () {
      this.$refs['modal-input'].fecharModal();
      let adicionarParticipante = [];
      let participante = this.participanteSelecionado.map((p) => {
        return {
          participante: new DropdownModel(p.participante),
          setor: p.setor ? new DropdownModel(p.setor) : null,
        };
      });

      if (!this.multiplos) {
        if (this.participanteSelecionado.length > 0)
          participante = new DropdownModel(this.participanteSelecionado)[0];
      }

      participante.forEach((participante) => {
        const participanteEncontrado = this.form.find((item) => {
          return item.participante.value == participante.participante.value;
        });

        if (!participanteEncontrado)
          adicionarParticipante.push({ ...participante, setor: null });
      });

      this.adicionarLinha(adicionarParticipante);
    },
  },
};
</script>
<style lang="scss">
.tabela-dados-procedimentos-eletronicos {
  th {
    background-color: $color-primary-grey-100;
  }

  thead {
    height: 48px;
  }
}
</style>
